select option {
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  color: rgb(229, 229, 229);
  background-color: rgb(23, 23, 23);
  &:focus {
    color: rgb(38, 38, 38);
  }
  &:hover {
    background-color: rgb(38, 38, 38) !important;
  }
  cursor: pointer;
}
