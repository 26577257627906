
// loading custom
.bounce-loading {
    display: flex;
    justify-content: center;
  
    div {
      margin: 0 4px;
      border-radius: 50%;
      animation: bounce-loader 2s infinite;
    }
  
    div:nth-child(2) {
      animation-delay: 0.1s; /* Adjusted delay */
    }
  
    div:nth-child(3) {
      animation-delay: 0.2s; /* Adjusted delay */
    }
  
    div:nth-child(4) {
      animation-delay: 0.3s; /* Adjusted delay */
    }
    
    div:nth-child(5) {
      animation-delay: 0.4s; /* Adjusted delay */
    }
  
    div:nth-child(6) {
      animation-delay: 0.5s; /* Adjusted delay */
    }
  
    div:nth-child(7) {
      animation-delay: 0.6s; /* Adjusted delay */
    }
    
    div:last-child {
      animation-delay: 0.7s; /* Adjusted delay */
    }
  }
  @keyframes bounce-loader {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  