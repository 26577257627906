.toast {
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 9999999999;
  &.top {
    top: 0;
  }
  &.bottom {
    bottom: 0;
  }
  &.success {
    background: #12B76A;
  }
  &.error {
    background: #f04438;
  }
  &.warning,
  &.warn {
    background: #FEC84B;
  }
  &.info {
    background: #53BAFD;
  }
}
