.bottom-sheet {
  position: fixed;
  width: 100%;
  right: 0;
  bottom: -100%;
  transition: all 0.4s ease-in-out !important;
  transition-delay: 0.1s;
  z-index: 100;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Màu đen mờ với độ trong suốt 50% */
  transition: all 0.3s ease-in-out !important;
  visibility: hidden;
  opacity: 0;
  z-index: -9999;
}
