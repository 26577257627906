$main-bg-color: #f6f6f6;
$main-border-color: #e5e5e5;
$btn-bg-color: #bfb3ff;
$secondary: #fc3039;
$secondary-hover: #db2b34;
$table-row-color: #e4e2ec;
$secondary-light: #bfb3ff;
$color-primary: linear-gradient(90deg, #32d583 0%, #1a6f45 100%);
$color-primary-hover: linear-gradient(135deg, #d56120 0%, #e49718 100%);
$color-primary-light: #fede77;
$color-primary-border: #443494;
$color-dark-orange: #ff8900;
$color-secondary: rgba(255, 144, 0, 1);
$color-notice: rgba(199, 0, 57, 1);
$color-border: rgb(201, 201, 201);
$color-background: rgba(30, 30, 30, 1);
$color-background2: rgba(44, 44, 44, 1);
$color-background3: rgba(18, 18, 18, 1);
$color-background4: rgba(255, 255, 255, 0.2);
$color-background5: #1e1e1e;
$color-layout: rgba(33, 39, 36, 1);
$color-alert: rgba(252, 114, 122, 0.5);
$color-black: rgb(0, 0, 0);
$color-white: rgb(255, 255, 255);
$color-bet-layout: rgba(125, 123, 121, 0.25);
$color-bar: rgba(53, 57, 65, 1);
$input-focus: rgba(232, 196, 184, 1);
$color-text-leftside: #dddddd;
$color-border-leftside: #989090;
$color-footer: #aeb0b3;
$color-pale-rose: #d9e8d7;
$color-success: #66c454;
$color-error: red;
$color-warning: rgba(242, 191, 79, 1);
$color-haba-bg: #31425a;
$color-account-bg: #f2f1ef;
$color-slot-bg: #21272e;
$color-green: green;
$color-midnight-moss: #212724;
$color-deep-sea: #14805e;
$color-bland-blue: #687779de;
$color-light-blue: #1218c1;
$color-shady-lady: #9e9a98;
$dark-red: #b9313d;
$color-half-baked: #5e9294;
$color-link: #feb15a;
$color-link-hover: #ac7600;
$color-gray1: rgba(245, 245, 245, 1);
$color-gray2: rgba(238, 238, 238, 1);
$color-gray3: rgba(224, 224, 224, 1);
$color-gray4: rgba(189, 189, 189, 1);
$color-gray5: rgba(158, 158, 158, 1);
$color-gray6: rgba(117, 117, 117, 1);
$color-gray7: rgba(97, 97, 97, 1);
$color-gray8: rgba(66, 66, 66, 1);
$color-gray9: rgba(33, 33, 33, 1);
$shadow-popup2: 0px 4px 4px 0px rgba(0, 0, 0, 0.3),
  0px 8px 12px 6px rgba(0, 0, 0, 0.15);
$font: "Open Sans", sans-serif;
$root-container-width: 1420px;
$container-width: 1340px;
$lottery-module-width: 1679px;
$laptop-width: 1366px;
$imagesPath: "/assets/images/";
.background-black-opa-7 {
  background: rgba(black, 0.7);
}

.mask-layer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(black, 0.8);
}

.custom-arrow {
  &--line {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-width: 6px 6px 0 0;
    border-color: white;
    border-style: solid;
    transform: rotate(45deg);
  }
  &--triangle {
    cursor: pointer;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid rgba(black, 0.8);
  }
  &--e-triangle {
    cursor: pointer;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 34.64px solid rgba(black, 0.8);
  }
}
.bg-gradient-primary {
  background: linear-gradient(90deg, #32d583 0%, #1a6f45 100%);
}
.bg-gradient-primary-hover {
  background: linear-gradient(90deg, #28ac6a 0%, #135333 100%);
}
$color-base-bg: #e7e7e7;
$color-base-txt: #a6a6a6;
$text-black: #161616;
$header-bg: #1b1b1b;
.text {
  &--white {
    color: #fff;
  }
  &--black {
    color: #2c2c2c;
  }
  &--primary {
    color: #fdde0b;
  }
  &--secondary {
    color: #bfb3ff;
  }
  &--success {
    color: rgba(35, 155, 86, 1);
  }
  &--error {
    color: rgba(176, 58, 46, 1);
  }
}
