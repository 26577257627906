.btn-signin:hover {
  background: linear-gradient(90deg, #28ac6a 0%, #135333 100%);
}
.popover-menu-user {
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
}

.nav-item {
  color: #373737;
}
.popover-inbox-user {
  &::before {
    position: absolute;
    top: -12px;
    right: 14px;
    content: "";
    border-bottom: 12px solid white;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    z-index: 3;
  }
}
