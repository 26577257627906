.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  .modal-wrapper {
    position: relative;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-opacity {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(0 0 0 / 34%);
      backdrop-filter: blur(0.5px);
    }
    .modal-overlay {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.8);
      backdrop-filter: blur(2px);
    }
    .w700 {
      max-width: 700px !important;
    }
    .modal {
      position: relative;
      z-index: 1;
      background: var(--white-color);
      padding: 8px;
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 800px;
      .modal-body {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .modal-title {
          color: var(--primary-color);
          flex: 1;
          text-align: center;
          font-size: 16px;
          &.back-color {
            color: var(--black-color);
          }
          &.pl {
            padding-left: 32px;
          }
        }
        .btn-close-modal {
          padding: 0 4px;
          width: 32px;
          height: 32px;
          background: none;
          border: none;
          color: var(--primary-color);
          font-size: 24px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}
